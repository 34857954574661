<template>
    <div>
        <b-badge class="badge" v-if="value.estimated_time" pill><b-icon icon="clock-history" font-scale="1"></b-icon> {{value.estimated_time}} {{ $t('Surveys.SurveyElement.minutes_abbreviated')}}</b-badge>
        <b-badge class="badge" v-if="value.xp_rewards" pill>{{value.xp_rewards}} <b-icon icon="star-fill" font-scale="1"></b-icon></b-badge> 
        <b-badge class="badge" v-if="value.vp_rewards" pill>{{value.vp_rewards}} <b-icon icon="credit-card2-back" font-scale="1"></b-icon></b-badge> 
        <b-badge class="badge" pill><b-icon icon="cloud-arrow-up-fill"></b-icon> <span v-if="value.max_responses>0">{{value.max_responses}}</span><span v-if="value.max_responses==0"> {{ $t('Surveys.SurveyElement.unlimited')}}</span>  {{ $tc('Surveys.SurveyElement.times', value.max_responses)}}.</b-badge>
    </div>
</template>

<script>

export default {
  name: 'SurveyBadges',
  props: [
      'value',
  ]
}
</script>

<style scoped>
.badge {
    margin-right: 3px;
}
</style>