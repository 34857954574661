<template>
    <b-container v-intro="$t('Dashboard.intro.start')" v-intro-autostart="false" >
            <b-modal v-model="show_vp_assistant" :title="$t('Dashboard.wizard.title')" centered @ok="handleVPOk">
                {{ $t('Dashboard.wizard.leader', {name: user.first_name}) }}
                <hr />
                <b-form>
                    <b-form-group
                        id="vp-select-group"
                        :label="$t('Dashboard.wizard.vp')"
                        label-for="vp-select-1"
                    >
                    
                    <b-form-select id="vp-select-1" v-model="user.profile.needs_vp" :options="vp_options"></b-form-select>
                    </b-form-group>

                    <b-form-group
                        id="vp-input-group"
                        label="Matrikelnummer"
                        label-for="vp-input-1"
                        :description="$t('Dashboard.wizard.registration_number')"
                    >
                        <b-form-input
                        id="vp-input-1"
                        v-model="user.profile.matrikel"
                        type="text"
                        placeholder="1231232"
                        required
                        ></b-form-input>
                    </b-form-group>
                </b-form>   
            </b-modal>
        <b-row>
            <b-col>
                <h1>{{$t('Dashboard.hello')}}, {{user.first_name}}! 
                    <b-img v-if="user.profile.level.icon" right :src="user.profile.level.icon" height="90px" alt="Level Icon"></b-img>
                </h1>
                <p class="lead">{{getTranslated(profile.level.translations, 'name')}} {{ $t('Dashboard.with')}} <b-link v-intro-hint="$t('Dashboard.intro.xp_log')" v-intro-step="5" to="/xplog"><b>{{metrics.xp}}</b> <small class="text-muted">XP</small>, {{$t('Dashboard.worth')}} {{(metrics.vp).toFixed(2)}} <small class="text-muted">VP</small></b-link> </p>
            </b-col>
        </b-row>
        <b-row>
            <b-col v-intro="$t('Dashboard.intro.profile')" >
 <b-card-group columns>                 
                        
                <b-card
                    :title="$t('Dashboard.your_images')"
                    >
                    <b-card-text class="text-center">
                        <h1 class="display-1">{{metrics.images}} <small class="text-muted"><b-icon icon="card-image" font-scale=".5"></b-icon></small> </h1>
                        <small class="text-muted"><b-icon icon="arrow-up"></b-icon> {{ $tc("Dashboard.upload_next_level", ((next_level.threshold - metrics.xp) / settings('UPLOAD_XP')).toFixed(0), {amount: ((next_level.threshold - metrics.xp) / settings('UPLOAD_XP')).toFixed(0)}) }}</small>

                    </b-card-text>

                </b-card>

                                <b-card
                    :title="$t('Dashboard.your_annotations')"
                    v-if="user.is_superuser"
                    >
                    <b-card-text class="text-center">
                        <h1 class="display-1">{{metrics.annotations}} <small class="text-muted"><b-icon icon="textarea" font-scale=".5"></b-icon></small></h1>
                        <small class="text-muted"><b-icon icon="arrow-up"></b-icon> {{ $tc("Dashboard.annotation_next_level", ((next_level.threshold - metrics.xp) / settings('ANNOTATION_XP')).toFixed(0), {amount: ((next_level.threshold - metrics.xp) / settings('ANNOTATION_XP')).toFixed(0)}) }} </small>

                    </b-card-text>

                </b-card>

                <TimeCard :xp="metrics.xp" :threshold="next_level.threshold" :duration="metrics.duration" />

    
        </b-card-group>
            </b-col>
            </b-row>    
                        <b-progress  v-intro="$t('Dashboard.intro.progress')" :max="next_level.threshold" height="2rem">
                            <b-progress-bar :value="metrics.xp">
                            <span>{{ $t('Dashboard.xp_missing_next_level', {amount: next_level.threshold - metrics.xp})}} </span>
                        </b-progress-bar>
                        </b-progress>
                        <hr/>
        <b-row>
            <b-col>
                 <b-card-group columns>                 
                <b-card v-intro="$t('Dashboard.intro.upload')"
                 :title="$t('Dashboard.upload_new_image')">
                        <b-card-text class="text-center">
                                <b-button @click="goto('image/upload')" size="lg" class="mb-2">
                                    <b-icon font-scale="6" icon="upload" aria-label="Help"></b-icon>
                                </b-button>
                        </b-card-text>
                </b-card>
                                <b-card :title="$t('Dashboard.unpublished_images')"  v-intro="$t('Dashboard.intro.publish')">
                    <ImageCard v-if="unpublished_image" destination="meta" :image="unpublished_image" />
                                          <div v-else class="text-muted">
                        <div class="d-flex justify-content-center"><b-icon font-scale="2.5" icon="emoji-heart-eyes"></b-icon></div>
                            <div class="d-flex justify-content-center">
                                <p>{{ $t('Dashboard.no_next_unpublished_image') }}</p>
                            </div>
                    </div>     
                </b-card>
                <b-card v-intro="$t('Dashboard.intro.annotate')" v-if="user.is_superuser"
                 :title="$t('Dashboard.next_for_annotation')">
                    <ImageCard v-if="next_image" :image="next_image" />
                      <div v-else class="text-muted">
                        <div class="d-flex justify-content-center"><b-icon font-scale="2.5" icon="emoji-sunglasses"></b-icon></div>
                            <div class="d-flex justify-content-center">
                                <p>{{ $t('Dashboard.no_next_image') }}</p>
                            </div>
                    </div>                  
                </b-card>
                <b-card v-intro="$t('Dashboard.intro.survey')"
                 :title="$t('Dashboard.next_survey')">
                    <SurveyElement v-if="profile.survey_step" :survey="profile.survey_step.survey" />
                    <div v-else class="text-muted">
                        <div class="d-flex justify-content-center"><b-icon font-scale="2.5" icon="eye-slash"></b-icon></div>
                            <div class="d-flex justify-content-center">
                                <p>{{ $t('Dashboard.no_next_survey') }} <small><b-link class="button" to="surveys">{{ $t('Dashboard.check_survey_list') }}</b-link></small></p>
                            </div>
                    </div>

                </b-card>

                 </b-card-group>
            </b-col>
        </b-row>

    </b-container>
</template>

<script>
import ImageCard from '../components/dashboard/ImageCard'
import TimeCard from '../components/dashboard/TimeCard'
import SurveyElement from '../components/surveys/SurveyElement'
import { apiService, introService } from '../services'
import { translationService } from '../services'

export default {
  name: 'Dashboard',
  props: {
      'user': Object,
      'settings': Function,
  }
  ,
  components: {
      ImageCard,
      SurveyElement,
      TimeCard
  },
  computed: {
      showIntro(){
          return !introService.check(this.name, this.version)
      }
  },
  methods: {
      getTranslated: translationService.getTranslated,
      goto(destination) {
          this.$router.push(destination)
      },
      handleVPOk(){
          let modified_profile = this.user.profile

          apiService.get({url: modified_profile.url, body: modified_profile, method: "PATCH"})
            .then(response => {
                    this.$store.dispatch('authentication/updateUser')
                    this.$emit('toast', 'Updated Profile', `Updated your User Profile ${response}.`, 'success')
            })

      },
      startIntro(){
        this.$intro().start(); // start the guide
        this.$intro().showHints(); // show hints
      }
  },
  data() {
      return {
          name: "Dashboard",
          version: 1,
          profile: null,
          metrics: null,
          levels: null,
          next_level: null,
          next_image: null,
          unpublished_image: null,
          show_vp_assistant: false,
          vp_options: [
              { value: true, text: 'Yes'},
              { value: false, text: 'No'}
            ],
        
  }
  },
  mounted() {
    apiService.get({url: `metrics`, method: 'GET'})
      .then(response => {   
          this.metrics = response     
        })
        .then(() => apiService.get({url: `level/`, method: 'GET'})
            .then(response => {   
                this.levels = response.results     
                })
                .then(() => {
                    
                        this.profile = this.user.profile    // TODO: Relict from unnested times, remove this.profile
                        if(this.profile.needs_vp==null)
                            this.show_vp_assistant = true
                        let next_level = this.levels.filter(item => item.threshold >= this.metrics.xp)
                        if(next_level)
                            this.next_level = next_level[0]
                }) 
        )

    // Query the next image to be annotated
    apiService.get({url: 'nextimage/?page_size=1', method: 'GET'})
        .then(response => {
            if(response.count > 0){
                this.next_image = response.results[0]
            }
        })

    // Query the last unpublished images
    apiService.get({url: 'images/?unpublished=true&page_size=1', method: 'GET'})
        .then(response => {
            if(response.count > 0){
                this.unpublished_image = response.results[0]
            }
        })  
  },

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
a {
    color: #212529;
}
</style>
