
<template>
<b-link :href="link">
             <b-card
                overlay
                :img-src="thumbnail"
            >
            </b-card>

</b-link>
</template>

<script>

export default {
  name: 'ImageCard',
  props: [
      'image',
      'destination'
  ],
  computed: {
      thumbnail() {
         return `${process.env.VUE_APP_DJANGO_BASE_URL}image/thumbnail/${this.image.id}`
      },
      link() {
          let destination = 'annotate'
          if(this.destination){
              destination = this.destination
          }
          return `/image/${destination}/${this.image.id}`
      }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
