
<template>
                <b-card
                    :title="$t('Dashboard.TimeCard.title')"
                    >
                    <b-card-text class="text-center">
                        <h1 class="display-1">{{(duration / 60).toFixed(0)}} <small class="text-muted"><b-icon icon="clock-history" font-scale=".5"></b-icon></small></h1>
                        <small class="text-muted"><b-icon icon="arrow-up"></b-icon> {{ $tc('Dashboard.TimeCard.time_next_level', ((threshold - xp) / 2).toFixed(0), {time: ((threshold - xp) / 2).toFixed(0)}) }}</small>

                    </b-card-text>

                </b-card>
</template>

<script>

export default {
  name: 'TimeCard',
  props: [
      'xp',
      'threshold',
      'duration'
  ],
  computed: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
