<template>
  <b-card header-tag="header" footer-tag="footer" >
            <template #header>
                <h3>{{getTranslated(survey.translations, 'title')}}</h3>                    
               <SurveyBadges v-model="survey" />
            </template>

            <template #footer>
                <b-button variant="primary" v-if="!returning" :to="link()">{{ $t('Surveys.SurveyElement.take_survey') }}</b-button>
                <b-button variant="primary" v-if="returning" :to="link(-1)">{{ $t('Surveys.SurveyElement.new_response') }}</b-button>

            </template>
        <b-overlay :show="data_loading" rounded="sm">             
          <b-card-body>
                <b-card-text>
            <p class="lead">{{getTranslated(survey.translations, 'description')}}</p>
                <div v-if="returning">
                <hr />
              <h5><b-icon icon="cloud"></b-icon> {{$t('Surveys.SurveyElement.unsubmitted_responses')}}</h5>
              <b-list-group >
                <b-list-group-item v-for="response in responses" v-bind:key="response.id" >
                  <a :href="link(response.id)">{{ $t('Surveys.SurveyElement.response_from', { date: formatDate(response.modified)}) }}</a>
                  <b-button-group class="float-right">
                      <b-button variant="primary" :to="link(response.id)">{{ $t('start') }}</b-button>
                      <b-button variant="danger" :title="$t('delete')" v-on:click="delete_response(response.url)"><b-icon icon="trash"></b-icon> </b-button>
                  </b-button-group>                      
                  </b-list-group-item>
              </b-list-group>
                </div>
</b-card-text>
            </b-card-body>


</b-overlay>
  </b-card>
</template>

<script>
import { apiService } from '../../services'
import { translationService } from '../../services'
import moment from 'moment';
import SurveyBadges from './SurveyBadges.vue'

export default {
  name: 'SurveyElement',
  components: {
    SurveyBadges,
  },
    props: {
      'user': Object,
      'settings': Function,
      'survey': Object,
  },
  computed: {
  },
  data() {
      return {
          data_loading: true,
          responses: null,
          returning: false,
      }
  },
methods: {
  getTranslated: translationService.getTranslated,
  link(response_id){
          if(!response_id)
            return `surveys/${this.survey.id}`
          
          return `surveys/${this.survey.id}/?response=${response_id}`

      },
    init() {
    this.data_loading = true
    this.returning = false

    apiService.get({url: `surveyresponses/?survey=${this.survey.id}&completed=False`, method: 'GET'})
          .then(response => {
              if(response){
                  this.responses = response.results
                  if(response.count > 0)
                    this.returning = true
              } else {
                  // TODO!
              }
            this.data_loading = false  
          })
    },
    formatDate(date){
      return moment(date).format('MMMM Do YYYY, h:mm:ss a')
    },
        delete_response(url){
    apiService.get({url, method: 'DELETE'})
    .then(response => {
      console.log(response)
      this.init()
    })
        }
  },
  mounted() {
    this.init()
  },
};
</script>

<style scoped>
.badge {
    margin-right: 3px;
}
</style>